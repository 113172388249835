.container {
  max-width: 400px;
  width: 100%;
}

.inputFormDark {
  border-radius: var(--card-border-radius);
  background-color: var(--background-input-dark);
  color: var(--text-white);
  padding: 1rem 0.75rem;
  outline: none;
  border: 0;
  width: 100%;
  font-size: 16px;
}

.inputFormLight {
  border-radius: var(--card-border-radius);
  background-color: var(--background-highlight);
  color: var(--text);
  padding: 1rem 0.75rem;
  outline: none;
  border: 0;
  width: 100%;
  font-size: 16px;
}

.inputFormBorder {
  border-radius: var(--card-border-radius);
  border: 1px solid var(--background-highlight-highlight);
  color: var(--text);
  padding: 1rem 0.75rem;
  outline: none;
  width: 100%;
  font-size: 16px;
}


.textArea {
  min-height: 100%;
  resize: vertical;
}

.inputOnly {
  background: none;
  border: none;
  outline: none;
}

.or {
  width: 100%;
}

.or hr {
  flex: 1;
  background-color: var(--text-lighter);
  opacity: 0.2;
}

.inputError {
  border: 2px solid var(--danger-soft);
}

.error {
  border-radius: var(--button-border-radius);
  border: 1px solid var(--danger-background);
  color: var(--danger);
  padding: 0.75rem 1rem;
  outline: none;
  width: 100%;
}

.selectOptionLight {
  border-radius: var(--card-border-radius);
  background-color: var(--background-highlight);
  color: var(--text);
  padding: 1rem;
  outline: none;
  border: 0;
  width: 100%;
  cursor: pointer;
}

.selectOptionLightActive {
  background-color: var(--accent);
  font-weight: 600;
  color: white;
}

.uploadImagePreview {
  border: 1px solid var(--background-highlight-highlight);
  border-radius: var(--button-border-radius);
  position: relative;
}
