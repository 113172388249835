.buttonBase {
  border-radius: var(--card-border-radius);
  color: var(--text);
  padding: 1.2rem 1rem;
  outline: none;
  border: 0;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.button {
  background-color: var(--background-button);
  border: 1px solid var(--border-modal);
  color: var(--text-lighter);
}

.button:focus {
  outline: none !important;
}

.shadow {
  box-shadow: var(--shadow-modal);
}

.buttonAccent,
.buttonAccent[disabled] {
  border: none;
  background-color: var(--accent);
  color: var(--text-on-accent) !important;
  /* background-color: rgb(125, 68, 250); */
  /* background-image: linear-gradient(90deg, rgb(143, 45, 240), rgb(60, 92, 207)); */
}

.buttonAccentBorder,
.buttonAccentBorder[disabled] {
  background: none;
  border: 1px solid var(--accent);
  color: var(--accent);
  /* background-color: rgb(125, 68, 250); */
  /* background-image: linear-gradient(90deg, rgb(143, 45, 240), rgb(60, 92, 207)); */
}


.buttonAccentBorder:hover {
  background-color: var(--accent-light);
  border: 1px solid var(--accent);
  color: var(--accent);
  /* background-color: rgb(125, 68, 250); */
  /* background-image: linear-gradient(90deg, rgb(143, 45, 240), rgb(60, 92, 207)); */
}

.buttonAccent a {
  color: var(--text-on-accent) !important;
}

.buttonBase[disabled],
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.small {
  border-radius: 999px !important;
  padding: 7px 12px !important;
  height: auto;
}

.xSmall {
  border-radius: 999px !important;
  padding: 2px 12px !important;
  height: auto;
}

.buttonBlack,
.buttonBlack[disabled] {
  background-color: var(--background-invert) !important;
  border: none;
  color: var(--text-invert) !important;
}


.buttonBlack:hover {
  background-color: var(--background-invert) !important;
  color: var(--text-invert) !important;
}

.buttonGreyTransparent,
.buttonGreyTransparent[disabled] {
  background-color: var(--light-transparent) !important;
  border: none;
  color: var(--text) !important;
}


.buttonGreyTransparent:hover {
  background-color: var(--light-transparent) !important;
  color: var(--text) !important;
}


.notifTopRight {
  position: absolute;
  left: 100% !important;
  top: 0px !important;
}

.loading {
  color: rgba(0, 0, 0, 0);
}

.accentLoader {
  position: absolute;
  left: calc(50% - (1em * 0.5));
  top: calc(50% - (1em * 0.5));
}

.buttonRounded {
  border-radius: 999px;
}
