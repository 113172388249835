.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  max-width: 900px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background);
  flex-grow: 1;
}


.containerOnboarding {
  max-width: 500px;
  max-height: calc(100% - 150px);
}

.onboardingMidSection {
  max-width: 100%;
  flex-grow: 1;
}

.centeredContainer {
  max-width: 900px;
  width: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  flex-grow: 1;
}

.siteContainerHighlight {
  max-width: 1200px;
  width: 100%;
  padding: 0 1rem;
  background-color: var(--background-highlight);
}

.page {
  position: relative;
  width: 100%;
  height: 100vh;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}


.cardLight {
  border-radius: var(--card-border-radius);
  background-color: var(--background-highlight);
  color: var(--text);
  padding: 2rem;
  outline: none;
  width: 100%;
  cursor: pointer;
}


.settingsIcon {
  background-color: black;
  box-shadow: var(--shadow-button);
  border-radius: 999px;
  color: var(--text-on-accent);
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addIcon {
  background-color: var(--accent);
  box-shadow: var(--shadow-button);
  border-radius: 999px;
  color: var(--text-on-accent);
  min-width: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 120px;
}

.optionIcon {
  width: 30px;
  height: 30px;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionIcon svg {
  margin: 0;
}

.doneButton {
  position: fixed !important;
  top: 15px;
  right: 10px;
  z-index: 1;
}

.previewButton {
  position: fixed !important;
  top: 15px;
  left: 10px;
  z-index: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.pageEditor {
  background: black !important;
  height: 100%;
}

.outerEditorContainer {
  padding-top: 5px;
  padding-bottom: 70px;
  overflow-y: auto;
}

.innerEditorContainer {
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: var(--button-border-radius);
}

.previewPopup {
  padding: 0px !important;
  overflow: hidden;
  position: relative;
}
