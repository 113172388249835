.form {
  max-width: 400px;
}

.pin_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.pin_input {
  width: 50px;
  min-width: 50px;
  padding: 15px 13px;
  text-align: center;
  border-radius: 5px;
  background-color: #f3f3f3;
  border: 0;
  margin-right: 5px;
  box-sizing: border-box;
  font-size: 20px;
}

.pin_row span {
  margin-right: 5px;
}

.disablePointer {
  cursor: default;
}

.resend {
  color: var(--accent);
  cursor: pointer;
}
